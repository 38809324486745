<template>
  <app-carousel :item-gap="0" :item-width="220" :items="list">
    <template #item="{ item }">
      <div class="h-full p-2">
        <teacher-card
          class="w-full"
          :show-description-short="showDescriptionShort"
          :teacher="item"
          @click="onClick(item)"
        />
      </div>
    </template>
  </app-carousel>
</template>

<script lang="ts" setup>
import type { TeacherListView } from "~/business-areas/teacher/teacher.model";

defineProps<{
  list: TeacherListView[];
  showDescriptionShort?: boolean;
}>();

const { $analytics } = useNuxtApp();

function onClick(teacher: TeacherListView) {
  return $analytics.track("teacher_clicked", {
    teacher: {
      id: teacher.id,
      full_name: teacher.full_name!,
      slug: teacher.slug!,
    },
  });
}
</script>
